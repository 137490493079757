import { useState, useEffect, useCallback } from 'react';
/** common a11y elements - START */
const OVERLAY = {
  id : 'su__overlay_announce',
  desc : 'opens in a new overlay'
};
const NEW_TAB = {
  id : 'su__new_tab_announce',
  desc : 'opens in a new tab'
};
const NEW_WINDOW = {
  id : 'su__new_window_announce',
  desc : 'opens in a new window'
};

const NEW_MENU = {
  id : 'su__new_menu_announce',
  desc : 'opens a new menu'
};
/** common a11y elements - END */

const LIST_VIEW = {
  id : 'su__list_view_btn',
  label : 'list view',
  desc : 'sets results section view as list view'
};
const GRID_VIEW = {
  id : 'su__grid_view_btn',
  label : 'grid view',
  desc : 'sets results section view as grid view'
};
const SEARCH = {
  id : 'su__search_btn_announce',
  label : 'search',
  desc : 'searches the entered text'
};
const ARIA_LABELS = {
  bookmark : 'add bookmark',
  saved : 'saved bookmarks',
  relevance : 'select relevance'
};

export const A11Y_IDS = {
  trap : 'su__modal_a11y_trap',
  feedbackQ1 : 'su__a11y_feedback_label1',
  feedbackQ2 : 'su__a11y_feedback_label2',
  facetDropdown : 'su__a11y_facet_dropdown',
  childTrap : 'su__modal_child_a11y_trap',
  skipToMainContent: 'su__skip_to_main_content'
};

export const A11Y_HIDDEN = 'su__acc-hidden';

export const ANNOUNCE = {
  OVERLAY,
  NEW_TAB,
  NEW_WINDOW,
  NEW_MENU,
  SEARCH,
  LIST_VIEW,
  GRID_VIEW
};

/**
 * useFocusTrap = custom hook for focus trap on modal
 * @param {void}
 * @returns {func} callable function
 */
export const useFocusTrap = () => {

  // add all the elements inside modal which you want to make focusable
  const  focusableElements = 'button:not(:disabled), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  const [ focusFirstElement, setFocusFirstElement ] = useState(false);
  const [ trap, setTrap ] = useState(false);
  const [ focusable, setFocusable ] = useState({ first: null, last: null });
  let observer, childObservers = []; // dom tree watcher

  /**
   * fn - keydown listener trapping 'Tab' and 'Shift + Tab' keys on modal
   * Gets re-calculated everytime the first and last focusable elements are re-calculated
   * @returns {void}
   */
  const fn = useCallback((e) => {
    let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === focusable.first) {
        focusable.last.focus(); // add focus for the last focusable element
        e.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === focusable.last) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        focusable.first.focus(); // add focus for the first focusable element
        e.preventDefault();
      }
    }
  },[focusable]);


  /**
   * resetTrap - reset trap logic
   * 1. remove old listener
   * 2. re-calculate the focusable elements present on the modal
   * 3. reset the first and last focusable element state
   * @param {bool} first - (optional) to reset with focus reset to first element
   * @returns {void}
   */
  const resetTrap = (first) => {
    first && setFocusFirstElement(true);
    setTimeout(()=>{
      document.removeEventListener('keydown', fn); // old listener cleanup
      const modal = document.querySelector(`#${A11Y_IDS.childTrap}`) || document.querySelector(`#${A11Y_IDS.trap}`);
      if(!modal) return;
      const focusableContent = modal.querySelectorAll(focusableElements);
      setFocusable((prev)=>({...prev, first: focusableContent[0], last : focusableContent[focusableContent.length - 1]}));
    });
  };


  /**
   * Re-attaches keydown listener with re-calculated values
   */
  useEffect(()=>{
    trap && document.addEventListener('keydown', fn); // attach new listener
    return () =>  document.removeEventListener('keydown', fn); // cleanup on unmount
  }, [ fn ]);

  /**
   * Focuses on default element (first) on first load
   */
  useEffect(()=>{
    focusFirstElement && focusable.first?.focus();
    focusFirstElement && setFocusFirstElement(false);
  },[ focusable ]);


  /**
   * treeWatcher - watches DOM tree modifications within the modal
   * calls resetTrap internally
   * @param {void}
   * @returns {void}
   */
  const treeWatcher = () => {
    const modal = document.querySelector(`#${A11Y_IDS.childTrap}`) || document.querySelector(`#${A11Y_IDS.trap}`);
    if (!modal) return;
    let handledAttributeChange = false;
    // Observe the modal's child elements for attribute changes
    const observeChildElements = (elements) => {
      childObservers = [];
      elements.forEach((element) => {
        const observer = new MutationObserver(() => {
          if (!handledAttributeChange) {
            handledAttributeChange = true;
            resetTrap();
            setTimeout(() => {
              handledAttributeChange = false;
            });
          }

          // observer.disconnect(); // Disconnect the observer after handling the attribute change
        });
        observer.observe(element, { attributes: true });
        childObservers.push(observer); // push to an array for a cleanup later
      });
    };

    // Initial observation for existing child elements
    const allChildren = modal.querySelectorAll('*');
    observeChildElements(allChildren);

    // Observe mutations in the child list to capture additions or removals of child elements
    observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          observeChildElements(mutation.addedNodes);
        }
      });
    });

    observer.observe(modal, { childList: true, subtree: true });
  };

  /**
   * Triggers the trap reset
   */
  useEffect(()=>{
    resetTrap();
    treeWatcher(); // initialize tree watcher
    return () => { childObservers.forEach((e)=>e?.disconnect()); observer?.disconnect(); }; // cleanup tree watchers
  },[trap]);

  /**
   * focusTrap - initializes the focus trap logic (exported in hook)
   * @param {bool} - trap [ set / unset the listener ]
   * @returns {void}
   */
  const focusTrap = (trap) => {
    setTrap(trap);
    setFocusFirstElement(true);
  };

  return [ focusTrap, resetTrap ];
};


export const focusTrap = (trap) => {
  // add all the elements inside modal which you want to make focusable
  const  focusableElements = '[button]:not([disabled="true"]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  let modal = document.querySelector(`#${A11Y_IDS.childTrap}`) || document.querySelector(`#${A11Y_IDS.trap}`);
  if(!modal) return;

  const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
  const focusableContent = modal.querySelectorAll(focusableElements);
  const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
  const fn = (e) => {
    let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus(); // add focus for the last focusable element
        e.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusableElement.focus(); // add focus for the first focusable element
        e.preventDefault();
      }
    }
  };

  document.removeEventListener('keydown', fn);
  if(trap){
    document.addEventListener('keydown', fn);
    firstFocusableElement.focus();
  }
};

export const tabIndexes = {
  tabIndex_0 : 0,
  tabIndex_minus_1 : -1,
  tabIndex_1:1,
  tabIndex_2:2

};

export const a11y = {
  ...ANNOUNCE,
  ARIA_LABELS,
  ROLES : {
    BTN : 'button',
    ALERT: 'alert',
    DIALOG: 'dialog',
    GROUP: 'group',
    LNK : 'link',
    TAB : 'tab',
    TABLIST: 'tablist',
    CHECKBOX : 'checkbox',
    IMAGE : 'img',
    RADIO: 'radio',
    RADIO_GROUP: 'radiogroup',
    MENU : 'menu',
    NAVIGATION : 'navigation',
    MENU_ITEM : 'menuitem',
    MENU_CHECKBOX : 'menuitemcheckbox',
    LIST : 'list',
    LIST_ITEM : 'listitem',
    PAGINATION:'Pagination'
  }
};

export default a11y;