const svgIcons = {
  Close: {
    className: '',
    viewBox: '0 0 329.27 329',
    path:
      'm194.8 164.77 128.21-128.21c8.3438-8.3398 8.3438-21.824 0-30.164-8.3398-8.3398-21.824-8.3398-30.164 0l-128.21 128.21-128.21-128.21c-8.3438-8.3398-21.824-8.3398-30.164 0-8.3438 8.3398-8.3438 21.824 0 30.164l128.21 128.21-128.21 128.21c-8.3438 8.3398-8.3438 21.824 0 30.164 4.1562 4.1602 9.6211 6.25 15.082 6.25 5.4609 0 10.922-2.0898 15.082-6.25l128.21-128.21 128.21 128.21c4.1602 4.1602 9.6211 6.25 15.082 6.25 5.4609 0 10.922-2.0898 15.082-6.25 8.3438-8.3398 8.3438-21.824 0-30.164z'
  },
  FacetClose: {
    className: '',
    viewBox: '0 0 24 24',
    path: 'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'
  },
  RecentSearch: {
    className: '',
    viewBox: '0 0 24 24',
    path: 'M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z'
  },
  Search: {
    className: '',
    viewBox: '0 0 24 24',
    path:
      'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'
  },
  SuggestIcon: {
    className: '',
    viewBox: '0 0 24 24',
    path:
      'M20 5H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2V8zm0 3h2v2h-2v-2zM8 8h2v2H8V8zm0 3h2v2H8v-2zm-1 2H5v-2h2v2zm0-3H5V8h2v2zm9 7H8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2V8h2v2zm3 3h-2v-2h2v2zm0-3h-2V8h2v2z'
  },
  Bookmark: {
    className: '',
    viewBox: '0 0 24 24',
    path:
      'M19.65,9.04l-4.84-.42L12.92,4.17a1,1,0,0,0-1.84,0L9.19,8.63l-4.83.41a1,1,0,0,0-.57,1.75l3.67,3.18-1.1,4.72a1,1,0,0,0,1.49,1.08L12,17.27l4.15,2.51a1,1,0,0,0,1.49-1.08l-1.1-4.73,3.67-3.18a1,1,0,0,0-.56-1.75Z'
  },

  Savebookmark: {
    className: '',
    viewBox: '0 0 24 24',
    path: [
      'M17,20H5a1,1,0,0,1-1-1V7A1,1,0,0,0,2,7V20a2.006,2.006,0,0,0,2,2H17a1,1,0,0,0,0-2ZM20,2H8A2.006,2.006,0,0,0,6,4V16a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2Zm0,10-2.5-1.5L15,12V4h5Z'
   ]
  },
  Nobookmark: {
    className: '',
    viewBox: '0 0 24 24',
    transforms: ['translate(3.534 3.534)'],
    path:
      'M53.7,64.039H12.34a3.457,3.457,0,0,1-3.447-3.447V19.233a3.447,3.447,0,0,0-6.893,0V64.039a6.914,6.914,0,0,0,6.893,6.893H53.7a3.447,3.447,0,1,0,0-6.893ZM64.039,2H22.68a6.914,6.914,0,0,0-6.893,6.893v41.36a6.914,6.914,0,0,0,6.893,6.893h41.36a6.914,6.914,0,0,0,6.893-6.893V8.893A6.914,6.914,0,0,0,64.039,2Zm0,34.466L55.423,31.3l-8.617,5.17V8.893H64.039Z'
  },
  Ok: {
    className: '',
    viewBox: '0 0 24 24',
    circle: [ 'translate(0)'],
    cx: '24',
    rx: '24',
    cy: '22',
    path:
      'M14 27l5.917 4.917L34 17'
  },
  listView: {
    className: '',
    viewBox: '0 0 21 21',
    path: [
      'M63,33C28.262,33,0,61.262,0,96c0,34.738,28.262,63,63,63s63-28.262,63-63S97.738,33,63,33z',
      'M63,193c-34.738,0-63,28.262-63,63c0,34.738,28.262,63,63,63s63-28.262,63-63C126,221.262,97.738,193,63,193z',
      'M63,353c-34.738,0-63,28.262-63,63c0,34.738,28.262,63,63,63s63-28.262,63-63C126,381.262,97.738,353,63,353z',
      'M465,49H207c-25.916,0-47,21.084-47,47s21.084,47,47,47h258c25.916,0,47-21.084,47-47S490.916,49,465,49z',
      'M465,209H207c-25.916,0-47,21.084-47,47s21.084,47,47,47h258c25.916,0,47-21.084,47-47S490.916,209,465,209z',
      'M465,369H207c-25.916,0-47,21.084-47,47s21.084,47,47,47h258c25.916,0,47-21.084,47-47S490.916,369,465,369z'
    ],
    transforms: [
      'translate(-1310 -189)',
      'translate(1310 189)',
      'translate(4.838)',
      'translate(17231.289 -20050.711)'
    ]
  },
  SearchTips: {
    className: '',
    viewBox: '0 0 21 21',
    Transforms: ['translate(0 -1.05)'],
    path: [
      'M0,24H24V0H0Z',
      'M3.55,5.96,4.96,4.55l1.79,1.8L5.34,7.76ZM11,5.05h2v-3H11Zm-10,9H4v-2H1ZM13,21V17.04l1-.58a4,4,0,1,0-4,0l1,.58V21h2m2,2H9V18.19a6,6,0,1,1,6,0V23Zm5-8.95h3v-2H20ZM17.24,6.34l1.79-1.8,1.41,1.41-1.8,1.79Z'
    ]
  },
  gridView: {
    className: '',
    viewBox: '0 0 21 21',
    rect: '',
    rx: '2',
    transforms: [
      'translate(17220 -20062)',
      'translate(17220 -20050.711)',
      'translate(17231.289 -20062)',
      'translate(17231.289 -20050.711)'
    ]
  },
  Language: {
    className: '',
    viewBox: '0 0 24 24',
    path:
      'M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z'
  },
  Advancesearch: {
    className: '',
    viewBox: '0 0 18 18',
    rect: '',
    rx: '0.964',
    transforms: [
      'translate(0 8.133)',
      'translate(0 1.715)',
      'translate(0 14.137)'
    ],
    circles: [
      'translate(10.288 6.406)',
      'translate(6.014)',
      'translate(2.356 12.422)'
    ]
  },
  ThumbsUp: {
    className: '',
    viewBox: '0 0 12 12',
    path:
      'M10.091,3.8H7.223l.432-1.828.014-.128a.568.568,0,0,0-.2-.424L6.986,1,4,3.636a.739.739,0,0,0-.268.564v4a.862.862,0,0,0,.909.8H8.727a.912.912,0,0,0,.836-.488l1.373-2.82A.708.708,0,0,0,11,5.4V4.6A.862.862,0,0,0,10.091,3.8Zm0,1.6L8.727,8.2H4.636v-4L6.609,2.464,6.1,4.6h3.986ZM1,4.2H2.818V9H1Z'
  },
  ThumbsDown: {
    className: '',
    viewBox: '0 0 12 12',
    path: 'M7.364,3H3.273a.912.912,0,0,0-.836.488L1.064,6.308A.708.708,0,0,0,1,6.6v.8a.862.862,0,0,0,.909.8H4.777l-.432,1.828-.014.128a.568.568,0,0,0,.2.424l.482.42L8,8.364A.739.739,0,0,0,8.273,7.8v-4A.862.862,0,0,0,7.364,3Zm0,4.8L5.391,9.536,5.9,7.4H1.909V6.6L3.273,3.8H7.364ZM9.182,3H11V7.8H9.182Z'
  },
  settings: {
    className: '',
    viewBox: '0 0 50 50',
    path: 'M47.16,21.221l-5.91-0.966c-0.346-1.186-0.819-2.326-1.411-3.405l3.45-4.917c0.279-0.397,0.231-0.938-0.112-1.282 l-3.889-3.887c-0.347-0.346-0.893-0.391-1.291-0.104l-4.843,3.481c-1.089-0.602-2.239-1.08-3.432-1.427l-1.031-5.886 C28.607,2.35,28.192,2,27.706,2h-5.5c-0.49,0-0.908,0.355-0.987,0.839l-0.956,5.854c-1.2,0.345-2.352,0.818-3.437,1.412l-4.83-3.45 c-0.399-0.285-0.942-0.239-1.289,0.106L6.82,10.648c-0.343,0.343-0.391,0.883-0.112,1.28l3.399,4.863 c-0.605,1.095-1.087,2.254-1.438,3.46l-5.831,0.971c-0.482,0.08-0.836,0.498-0.836,0.986v5.5c0,0.485,0.348,0.9,0.825,0.985 l5.831,1.034c0.349,1.203,0.831,2.362,1.438,3.46l-3.441,4.813c-0.284,0.397-0.239,0.942,0.106,1.289l3.888,3.891 c0.343,0.343,0.884,0.391,1.281,0.112l4.87-3.411c1.093,0.601,2.248,1.078,3.445,1.424l0.976,5.861C21.3,47.647,21.717,48,22.206,48 h5.5c0.485,0,0.9-0.348,0.984-0.825l1.045-5.89c1.199-0.353,2.348-0.833,3.43-1.435l4.905,3.441 c0.398,0.281,0.938,0.232,1.282-0.111l3.888-3.891c0.346-0.347,0.391-0.894,0.104-1.292l-3.498-4.857 c0.593-1.08,1.064-2.222,1.407-3.408l5.918-1.039c0.479-0.084,0.827-0.5,0.827-0.985v-5.5C47.999,21.718,47.644,21.3,47.16,21.221z M25,32c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C32,28.866,28.866,32,25,32z'

  },
  showEye: {
    className: '',
    viewBox: '0 0 18 18',
    path: 'M9.259,4.5A8.881,8.881,0,0,0,1,10.165a8.853,8.853,0,0,0,16.518,0A8.881,8.881,0,0,0,9.259,4.5Zm0,9.442a3.777,3.777,0,1,1,3.754-3.777A3.767,3.767,0,0,1,9.259,13.942Zm0-6.043a2.266,2.266,0,1,0,2.252,2.266A2.256,2.256,0,0,0,9.259,7.9Z'
  },
  hideEye: {
    className: '',
    viewBox: '0 0 18 18',
    path: 'M9.708,5.743a3.91,3.91,0,0,1,3.768,5.114L15.9,13.28a9.343,9.343,0,0,0,2.517-3.586A9.372,9.372,0,0,0,6.827,4.215L8.545,5.933a4.069,4.069,0,0,1,1.164-.19ZM2.354,3.1a.788.788,0,0,0,0,1.116l1.56,1.56A9.415,9.415,0,0,0,1,9.7,9.341,9.341,0,0,0,13.12,14.99l2.153,2.153a.789.789,0,1,0,1.116-1.116L3.478,3.1A.8.8,0,0,0,2.354,3.1ZM9.708,13.66A3.96,3.96,0,0,1,5.75,9.7a3.888,3.888,0,0,1,.388-1.694L7.381,9.25a2.718,2.718,0,0,0-.048.451,2.372,2.372,0,0,0,2.375,2.375,2.133,2.133,0,0,0,.451-.055L11.4,13.264A3.81,3.81,0,0,1,9.708,13.66ZM12.06,9.44A2.351,2.351,0,0,0,9.97,7.35Z'
  },
  editLayout: {
    className: '',
    viewBox: '0 0 172 172',
    path: 'M131.96744,14.33333c-1.83376,0 -3.66956,0.69853 -5.06706,2.09961l-12.23372,12.23372l28.66667,28.66667l12.23372,-12.23372c2.80217,-2.80217 2.80217,-7.33911 0,-10.13412l-18.53255,-18.53255c-1.40108,-1.40108 -3.23329,-2.09961 -5.06706,-2.09961zM103.91667,39.41667l-82.41667,82.41667v28.66667h28.66667l82.41667,-82.41667z'
  },
  Filter: {
    className: '',
    viewBox: '0 0 15 15',
    transforms: ['translate(-0.5 0)'],
    path: 'M13.966,0H1.047A.547.547,0,0,0,.5.547,5.346,5.346,0,0,0,2.293,4.54l2.4,2.131a1.951,1.951,0,0,1,.654,1.457v6.252a.548.548,0,0,0,.851.455l3.23-2.153a.548.548,0,0,0,.244-.455v-4.1a1.951,1.951,0,0,1,.654-1.457l2.4-2.131A5.346,5.346,0,0,0,14.513.547.547.547,0,0,0,13.966,0Z'
  },
  goToTop: {
    viewBox: '0 0 24 24',
    path:
      'M8 11h3v10h2V11h3l-4-4-4 4zM4 3v2h16V3H4z'
  },
  Rest: {
    viewBox: '0 0 24 24',
    path:
      'M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z'
  },
  ViewMore: {
    className: '',
    viewBox: '0 0 24 24',
    path: 'M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
  },
};
export default svgIcons;
