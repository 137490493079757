import React from 'react';
import ICONS from './icons';
import { a11y, tabIndexes } from '../../constants/a11y';

const Icon = ({
  className,
  IconName,
  IconClass,
  color,
  width,
  height,
  widthInner,
  heightInner,
  strokeWidthp,
  strokeLinecapp,
  strokeLinejoinp,
  strokeMiterlimitp,
  rx,
  transform,
  fill,
  cx,
  cy,
  r,
  onClick
}) => {
  return (
    <svg
      
      className={className}
      width={width}
      height={height}
      viewBox={ICONS[IconName].viewBox}
      onClick={onClick}
    >
      {!(ICONS[IconName].path == null) ? (
        <path d={ICONS[IconName].path} transform={transform} fill={color} className={IconClass} strokeWidth={strokeWidthp} strokeLinecap={strokeLinecapp} strokeLinejoin={strokeLinejoinp} strokeMiterlimit={strokeMiterlimitp}/>
      ) : null}

      {!(ICONS[IconName].rect == null) ? (
        <g transform={transform}>
          {ICONS[IconName].transforms.map((item, index) => (
            <rect
              key={index}
              width={widthInner}
              height={heightInner}
              rx={rx}
              transform={item}
              fill={fill}
            />
          ))}
          {!(ICONS[IconName].circles == null) ? (
            <g>
              {ICONS[IconName].circles.map((item, index) => (
                <circle
                  key={index}
                  cx={cx}
                  cy={cy}
                  r={r}
                  transform={item}
                  fill={fill}
                />
              ))}
            </g>
          ) : null}
        </g>
      ) : null}
    </svg>
  );
};

export default Icon;
