import React from 'react';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import IconColors from '../../../IconColors';
const AutoCompleteClearIcon = (props) => {
    try {
        let { showClearIcons, setShowAutoCompleteResult, setShowClearIcons, autocompleteSearchbox } = props;

        const ClearIcon = () => {
            autocompleteSearchbox.value = '';
            setShowClearIcons(false);
            setShowAutoCompleteResult(false);
            variables.searchCallVariables.from = 0;
            variables.searchCallVariables.pageNo = 1;
            variables.autocompleteCallVariables.searchString = '';
        };

        return (
            (showClearIcons || variables.searchCallVariables.searchString) &&
            <span className="su__input-close su__mr-4 su__animate-zoom su__position-absolute su__cursor" onClick={ClearIcon}>
                <svg width="20" height="20" viewBox="0 0 13 13">
                    <defs>
                    <clipPath id="clip-Close">
                        <rect width="13" height="13"></rect>
                    </clipPath>
                    </defs>
                    <g id="Close" clip-path="url(#clip-Close)">
                    <g id="Group_13926" data-name="Group 13926" transform="translate(-893.45 -736.45)">
                        <path
                        id="Path_13423"
                        data-name="Path 13423"
                        d="M9031.508,731.085l-12.276-12.276.724-.723,12.276,12.276Z"
                        transform="translate(-8125.781 18.364)"
                        ></path>
                        <path
                        id="Path_13424"
                        data-name="Path 13424"
                        d="M12.276,13,0,.723.723,0,13,12.276Z"
                        transform="translate(906.45 736.45) rotate(90)"
                        ></path>
                    </g>
                    </g>
                </svg>
            </span>
        );
    } catch (e) {
        console.log('Error in clear searchbox Icon component', e);
        return (<div></div>);
    }
};

export default React.memo(AutoCompleteClearIcon);