import React, { useState, useEffect, useRef, Fragment }from 'react';
import { useSelector, useDispatch } from 'react-redux';
import variables from './redux/variables';
import { autocomplete } from './redux/ducks';
import useComponentVisible from './event-handler/event';

import AutoCompleteSearchIcon from 'components/custom-components/search-box-search-icon-1728384226786/index.jsx';
import AutoCompleteClearIcon from 'components/custom-components/search-box-clear-icon-1728384179744/index.jsx';
import AutoCompleteLoading from 'components/section-components/search-box-loading/index.jsx';

import AutoSuggestion from 'components/custom-components/auto-suggest-autocomplete-app-1728364987690/index.jsx';
import AutoCompleteResultIcon from 'components/custom-components/auto-suggest-icon-1728365376534/index.jsx';
import AutoCompleteResultTitle from 'components/section-components/auto-suggest-title/index.jsx';
import AutoCompleteResultMetadata from 'components/section-components/auto-suggest-metadata/index.jsx';
import RecentSearch from 'components/custom-components/auto-suggest-recent-search-1728365756724/index.jsx';
const RecentSearchHeader = () =>{
  const searchResult = useSelector((state) => state.autocomplete);
  return(
   <Fragment>
   {searchResult?.recentSearchHistory?.length &&  
        <div className="custom-recent-search-heading">
                    <span>Your Recent Searches</span>
                  </div>}
   </Fragment>   
  )
}
import dataFormater from './function-library/dataFormatter';

const AutocompleteApp = () => {
  const dispatch = useDispatch();
  let autoCompleteResult  = useSelector((state) => { return state.autocomplete;  });
  let viewedResult = undefined;
  if(autoCompleteResult.length !== 0) {
    viewedResult = dataFormater(autoCompleteResult)?.viewedResult;
  }
  /** inputValue reference to DOM input element of autocomplete box */
  const inputValue = useRef(null);
  let { ref, isComponentVisible ,setIsComponentVisible} = useComponentVisible(true);
  /** 
   * showClearIcons state to manage show/hide clear icon 
   * true - Show clear icon
   * false - hide clear icon
   */
  const [showClearIcons, setShowClearIcons] = useState();

  /** 
   * showAutoCompleteResult state to manage show/hide autocomplete result dropdown
   * true - show autocomplete result dropdown
   * false- hide autocomplete result dropdown
  */
  const [showAutoCompleteResult, setShowAutoCompleteResult] = useState();

  const [prevIdCall, setPrevIdCall] = useState();
  
  /**
   * changeSearchDataOnArrowKey & previousAction to manage 
   * iterate over autocomplete result using ↓ and ↑ keyboard keys
   */
  const [changeSearchDataOnArrowKey, setChangeSearchDataOnArrowKey] = useState(Array(variables.autocompleteCallVariables.resultsPerPage).fill(null).map((_, i) => i));
  const [previousAction, setPreviousAction] = useState('');
  let [openInNewTab, setOpenInNewTab] = useState(null);

  /** To Keep autocomplete result open on scroll , change keepAutoCompleteResultOpenOnScroll to true  */
  variables.keepAutoCompleteResultOpenOnScroll = false;

  /** This Object can be modified conditionally to set Search API  Url 
   *  Blank Url means default Search Urls is getting used 
   */
  variables.userDefinedAutoCompleteSearchUrl = {
    url : '',
    req : {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(variables.autocompleteCallVariables)
    }
  };

  /** Setting up default result per page to 10 */
  variables.autocompleteCallVariables.resultsPerPage = autoCompleteResult && autoCompleteResult.searchClientSettings?Number(autoCompleteResult.searchClientSettings.autoComplete): 10;

  /**
   * searchAppisPresentOnSamePage - To check if Search App and Autocomplete App present on same page
   * if Both apps are present on same page , we can establish communication b/w them using below script in autocomplete.html file
   * https://<- instance name -> /resources/search_clients_custom/<- uid -> /communication.js
   */
  let searchAppisPresentOnSamePage = document.getElementById('search-box-search') ? true : false;

  /**
   * settings.searchBoxPlaceholder - Autocomplete App Search box placeholder ( no language support in standard code)
   * settings.redirectionLink - search page url 
   * settings.instantSearch - When Search App and Autocomplete Apps are present on same page with communication
   * established  , on every key press in autocomplete box ,updating the search query string in search app also along with results
   * without enter key
   *  
   */
  let settings = {
    searchBoxPlaceholder: 'Search here',
    bothAppOnSamePage: searchAppisPresentOnSamePage,
    autocompleteSearchbox : document.getElementById('search-box-autocomplete'),
    instantSearch: searchAppisPresentOnSamePage && (autoCompleteResult.searchClientSettings?.autoCompleteInstant == 1),
    // redirectionLink : autoCompleteResult.searchClientSettings?.redirectionUrl ? autoCompleteResult.searchClientSettings?.redirectionUrl : ''
    redirectionLink: window.scConfiguration?.redirection_url || ''
  };
  /**Redriection on search Icon click and enter press*/
  const redirection = (searchString) => {
    let searchVariable = searchString ? searchString : variables.autocompleteCallVariables.searchString;
    /** 
     *  - redirect from autocomplete app to search app 
     *  - Set Redirection url from admin panel , it is basically search app url
     *  - on Enter press in autocomplete app / search icon , redirection happen to mentioned
     *    url along with search Sting
     *  - no redirection if search App exist on same page
    */
    if (!settings.bothAppOnSamePage) {

     if(!settings.redirectionLink) settings.redirectionLink = `${window.location.href}/search`;

      window.open(`${settings.redirectionLink}?searchString=${searchVariable}`, '_self');     
    }else{
      document.getElementById('search-box-search').value = document.getElementById('search-box-autocomplete').value;
      document.getElementById('hit-me').click();
    }
  };
  useEffect(() => {
    document.body.classList.add('su__autocomplete-running');
  });
  /**
   * Initialising search call
   */
  useEffect(() => {
    variables.searchSource = 'autocomplete';
  }, []);
  
  /** it includes data from recent seach , autosuggestiona and hits */
  let [tempData, setTempData] = useState([]);

  useEffect(() => {
    let tempDataInner = [];
    autoCompleteResult && autoCompleteResult.recentSearchHistory &&
      autoCompleteResult.recentSearchHistory.length != 0 && tempDataInner.push(...autoCompleteResult.recentSearchHistory);
    autoCompleteResult && autoCompleteResult.result && tempDataInner.push(...autoCompleteResult.result.hits);
    variables.autocompleteCallVariables.searchString && tempDataInner.push({ 'title': variables.autocompleteCallVariables.searchString, 'type': 'userTypedData' });
    if (autoCompleteResult && autoCompleteResult.searchClientSettings) {
      setChangeSearchDataOnArrowKey(Array(Number(tempDataInner.length - 1)).fill(null).map((_, i) => i));
    }
    setTempData(tempDataInner);
  }, [autoCompleteResult]);  
 
  /**
   * dispatches the search action.
   * event.currentTarget.value - is current user typed search strign
   */
  const dispatchSearchAction = (event) => {
  
    /**on first load , intialing the states */
    let tempDataInner = [];
    autoCompleteResult && autoCompleteResult.recentSearchHistory &&
    autoCompleteResult.recentSearchHistory.length != 0 && tempDataInner.push(...autoCompleteResult.recentSearchHistory);
    autoCompleteResult && autoCompleteResult.result && tempDataInner.push(...autoCompleteResult.result.hits);
    variables.autocompleteCallVariables.searchString && tempDataInner.push({ 'title': variables.autocompleteCallVariables.searchString, 'type': 'userTypedData' });
    if (tempDataInner && tempDataInner.length != 0) {
      setChangeSearchDataOnArrowKey(Array(Number(tempDataInner.length - 1)).fill(null).map((_, i) => i));
    }
    setTempData(tempDataInner);
    setPreviousAction('');
    /**  
     * control,esc,shift, spacebar, 4 arrow key ,Alt
     * There should be no search hit on these keys 
     * 
     * No search hit on Ctrl + char or Alt + char 
    */

    if (![17, 27, 16, 32, 37, 38, 39, 40, 18].includes(event.keyCode) && !event.ctrlKey && !event.altKey) {
      /** Show clear icon if search query exist  */
      event.currentTarget.value ? setShowClearIcons(true) : setShowClearIcons(false);
      /** KeyCode 13 is for Enter Key */
      if (settings.instantSearch && event.keyCode !== 13) {
        /**case when instant search is on and both apps are on same page */
        variables.autocompleteCallVariables.searchString = event.currentTarget.value;
        /** Dispatch search call  */
        variables.searchSource = 'autocomplete';
        dispatch(autocomplete.start(variables.autocompleteCallVariables));
        inputValue.current.value = variables.autocompleteCallVariables.searchString;
      } else {
        setShowAutoCompleteResult(true);
        if (event.keyCode === 13) {
          if (tempData[changeSearchDataOnArrowKey[0]]?.href && previousAction) {
            inputValue.current.value = tempData[tempData.length - 1].title;
            var hrefValue = tempData[changeSearchDataOnArrowKey[0]].href;
            window.open(hrefValue)
            resultOpenNewTab(tempData[changeSearchDataOnArrowKey[0]], changeSearchDataOnArrowKey[0]);
            
          }else{
            if(openInNewTab !=null && previousAction != ' ' && previousAction != undefined){
              window.open(openInNewTab, '_blank');
              setOpenInNewTab(null);
            } else{
              /***on Enter press, redirect to search app and hide autocomplete result  */
              settings.redirectionLink && redirection(event.currentTarget.value);
            }
          }
          setShowAutoCompleteResult(false);
        } else {
          if (event.currentTarget.value) {
            /** 
              * setting autoCompleteResult.result state to null before sending new search call
              * So to clear old data in state , avoid flicker impact on autocomplete dropdown result
              * if result is null - loading is added below autocomplete searchbox(means waiting for response)
              */
            autoCompleteResult.result = null;
            /**  
             * event.persist() to save event asynchronously 
             * delay is added  to achieve debouncing so during that time event obj value should not be lost
             * event.currentTarget.value consist of our search text query
            */
            event.persist();
            let hasProp = event.currentTarget.hasOwnProperty('value');
            let value = event.currentTarget.value;
            /**
             * setTimeout of 500 ms before hitting search api
             * if we remove below setTimeout, search api hit will happen on every single keypress 
             * this put significant load of backend resources
             * 
             * So to avoid multiple hits , debounce is achived here
             * when user take pause for atleast 500ms while typing , autoCompleteSearchCall function is called
             */
            clearTimeout(prevIdCall);
            setPrevIdCall(setTimeout(() => { autoCompleteSearchCall(event, hasProp, value); }, 1000));
          }
          else { 
            /** keep autocomplete result suggestions hide when search query string is empty */
            setShowAutoCompleteResult(false);
          }
        }
      }
    } else if ([40].includes(event.keyCode)) { 
      /** 40 keycode for down arrow ↓ */
      autoCompleteResult && autoCompleteResult.result?.hits.length && handleUpDownArrowFunctionality('down');
    } else if ([38].includes(event.keyCode)) { 
      /** 38 keycode for up arrow ↑ */
      autoCompleteResult && autoCompleteResult.result?.hits.length && handleUpDownArrowFunctionality('up');
    }
  };

  const handleUpDownArrowFunctionality = (action) => {
    let data = changeSearchDataOnArrowKey;
    if (action == 'down' && previousAction) {
      data.push(data.shift());
    } else if (action == 'up' && previousAction) {
      data.unshift(data.pop());
    }

    if (tempData[data[0]] && tempData[data[0]].type && ['userTypedData', 'recentSearch', 'autoSuggestion'].includes(tempData[data[0]].type)) {
      inputValue.current.value = tempData[data[0]].title;
    } else {
      inputValue.current.value = tempData[data[0]].highlight.TitleToDisplayString[0] || tempData[data[0]].href;
      setOpenInNewTab(tempData[data[0]].href);
    }
    /** Move Focus to current highlighted result */
    tempData[data[0]].type != 'userTypedData' && document.getElementsByClassName('su__suggestions-list')[data[0]].focus();
    /**Move focus back to autocomplete app search box */
    document.getElementById('search-box-autocomplete').focus();
    setPreviousAction(action);
    setChangeSearchDataOnArrowKey([...data]);
  };

  const autoCompleteSearchCall = (e, hasProp, value) => {
    if (hasProp) {
      let check = 0;
      /** dispatch autocomplete search  */
      variables.autocompleteCallVariables.searchString = value;
    //   if((variables.searchClientType == 11 || variables.searchClientType == 12) && !window.su_utm) {
    //     const intervalId = setInterval(() => {
    //       check++;
    //       if((variables.searchClientType == 11 || variables.searchClientType == 12) && window.su_utm) {
    //         clearInterval(intervalId);
    //         performSearchCall();
    //       } else if (check > 3) {
    //         clearInterval(intervalId);
    //         performSearchCall();
    //       } 
    //     }, 60 * 100);
    //   } else {
        performSearchCall();
    //   }
    }

    function performSearchCall() {
      dispatch(autocomplete.start(variables.autocompleteCallVariables));
    }
  };

  useEffect(() => {
    inputValue.current.focus();
    /**Set changeSearchDataOnArrowKey based on search results  */
    if (autoCompleteResult && autoCompleteResult.searchClientSettings) {
      setChangeSearchDataOnArrowKey(Array(Number(autoCompleteResult.searchClientSettings.autoComplete)).fill(null).map((_, i) => i));
    }
  }, [autoCompleteResult && autoCompleteResult.searchClientSettings && autoCompleteResult.searchClientSettings?.autoComplete]);

  /**
   *  autocomplete gza function
  */
  const resultOpenNewTab = (item, index) => {
    setIsComponentVisible(false);
    window.analyticsRecordAlready = true;
    gza('search', {
      'searchString': variables.autocompleteCallVariables.searchString,
      'result_count': autoCompleteResult.result.hits.length,
      'page_no': 0,
      'uid': variables.autocompleteCallVariables.uid,
      'filter': [],
      'conversion': [{
        rank: (index + 1),
        url: item['href'],
        subject: item['highlight']['TitleToDisplayString'][0] || '',
        es_id: item['sourceName'] + '/' + item['objName'] + '/' + encodeURIComponent(item['_id']),
        sc_analytics_fields: item['trackAnalytics']
      }]
    });  
  };

  return (
    <div className={`su__app su__wrapper su_autocomplete-block ${viewedResult ? 'su__viewed-results' : ''}`} id="su_autocomplete-block">
      <section className="su__w-100 su__py-4 su__search_section su__bg-blue-grd">
        <div className="su__container">
          <form autoComplete="off" id="searchForm" className="su__search-forms su__m-0" onSubmit={(e) => { e.preventDefault(); }}>
            <div ref={ref} className="su__form-block su__w-100 su__position-relative">
              <div className="su__radius-2 su__d-flex su__position-relative">
                <input ref={inputValue} id="search-box-autocomplete" className="su__input-search su__w-100 su__su__font-14 su__text-black  su__border-none su__radius-2 su__pr-60" type="input" placeholder={settings.searchBoxPlaceholder} onKeyUp={(e) => dispatchSearchAction(e)} />
                <AutoCompleteClearIcon showClearIcons={showClearIcons} setShowAutoCompleteResult={setShowAutoCompleteResult} setShowClearIcons={setShowClearIcons} autocompleteSearchbox={settings.autocompleteSearchbox} />
                <AutoCompleteSearchIcon redirection={redirection} />
              </div>
              <AutoCompleteLoading isResultExist={autoCompleteResult && autoCompleteResult.result} searchString={inputValue.current?.value} />
              {isComponentVisible && (!showAutoCompleteResult ?
                null :
                <AutoSuggestion openInNewTab={openInNewTab}  resultOpenNewTab={resultOpenNewTab} currentSearchString={inputValue.current.value} autoCompleteResult={autoCompleteResult} dataType={tempData[changeSearchDataOnArrowKey[0]] && tempData[changeSearchDataOnArrowKey[0]].type ? 'recentSearch' : 'autosuggest'} component="autocomplete" >
                  <RecentSearchHeader position="aboveAllResult" />
                  <RecentSearch position="aboveAllResult" redirection={redirection}/>
                  <AutoCompleteResultIcon position="icon" />
                  <AutoCompleteResultTitle position="result" />
                  <AutoCompleteResultMetadata position="result" />
                  {/*   send position ->  belowAllResult to set component below all autocomplete results  */}
                  {/*   send position ->  aboveAllResult to set component above all autocomplete results  */}
         </AutoSuggestion>)}
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AutocompleteApp;