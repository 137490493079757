// import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/startWith'
import 'rxjs/add/operator/do'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/takeUntil'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/throttleTime'
import 'rxjs/add/operator/merge'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/from'
import 'rxjs/add/observable/timer'
import variables from './variables'
import { pageRating } from './ducks'
import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'


const pageRatingEpic = action$ =>
  action$.pipe(
    ofType(pageRating.start().type),
    mergeMap(async () => {
      let pageratingEndpoint = variables.pagerating.instanceName + variables.pagerating.pageratingEndpoint;
      let pageRatingData = variables.pageratingVariable;
      try {
        /** Get object of Regex for search page where page rating component is required */
        const resPageratingEndpoint = await fetch(pageratingEndpoint, pageRatingData)
        const result = await resPageratingEndpoint.json()
        /** Get object of Regex for Instance page where page rating component is required */
        // const resPageratingInstanceEndpoint = await fetch(pageratingInstanceEndpoint, pageRatingData)
        // const resultAnother = await resPageratingInstanceEndpoint.json()
        return pageRating.success({ ...result })
      }
      catch (err) {
        return pageRating.fail(err)
      }
    })
  )

export default pageRatingEpic
