/* eslint-disable react/jsx-key */

import React, { Fragment } from 'react';
import variables from '../../../redux/variables';
import { useDispatch, useSelector } from 'react-redux';
import { search } from '../../../redux/ducks';
import AutoCompleteResultIcon from 'components/custom-components/auto-suggest-icon-1728365376534/index.jsx';
import { tabIndexes } from '../../../constants/a11y';

const RecentSearch = (props) => {
  try {
    let { currentSearchString, dataType, redirection} = props;
    const searchResult = useSelector((state) => state.autocomplete);
    const dispatch = useDispatch();
    let searchCall = (value) => {
      if(props.component == 'autocomplete'){
        let autocompletediv = document.getElementById('search-box-autocomplete') ?? '';
        if(autocompletediv) autocompletediv.value = value;
        variables.autocompleteCallVariables.searchString = value;
      } else if(props.component=='searchbox'){
        variables.autocompleteCallVariables.searchString = '';
        document.getElementById('search-box-search').value = value;
        variables.searchCallVariables.searchString = value;
        variables.searchSource = 'recent-search';
        variables.searchCallVariables.pagingAggregation = [];
        dispatch(search.start(variables.searchCallVariables));
      }
    };
    return (
      <Fragment>
        {variables.autocompleteCallVariables.searchString && searchResult && searchResult.recentSearchHistory && searchResult.recentSearchHistory.length != 0 &&
          React.Children.toArray(searchResult.recentSearchHistory.map((item , index) => (
            <div key={index} data-key={index} tabIndex={tabIndexes.tabIndex_minus_1} onClick={() => {searchCall(item.title); redirection();}} className={`su__${item.type} su__align-items-center su__cursor su__suggestions-list su__bg-gray-hover su__d-flex su__font-14 su__text-black ${(item.title && currentSearchString === item.title && dataType === 'recentSearch') ? 'su__highlight_result' : ''}`}>
              <AutoCompleteResultIcon item={item} />
              <div className="su__suggestions-txt" >
                <div className={'su__suggestion-desc su__line-height-n su__font-11 su__color-lgray su__suggest'}>
                  <span className="su__color-gray su__f-bold">{item.title} </span>
                </div>
              </div>
            </div>
          )))}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in Recent Search component', e);
    return (<div></div>);
  }
};



export default RecentSearch;