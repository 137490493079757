import React, { Fragment } from 'react';

const AutoCompleteResultIcon = (props) => {
    try { let { item } = props;
        return (
            <Fragment>
                <div className="su__suggesticon su__mt-1">
                    {item.solved == 'Solved' ?
                        <svg className="su__mr-2" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none" /><path fill="#5bca51" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                        : null}
                    {item.solved == 'Unsolved' ?
                        <svg className="su__mr-2" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none" /><path fill="#ababab" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                        : null}
                </div>
                <div className="su__autosuggestion-icon">
                    {item.type === "searchString" ?
                        <svg className="su__mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20 5H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2V8zm0 3h2v2h-2v-2zM8 8h2v2H8V8zm0 3h2v2H8v-2zm-1 2H5v-2h2v2zm0-3H5V8h2v2zm9 7H8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2V8h2v2zm3 3h-2v-2h2v2zm0-3h-2V8h2v2z" /><path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none" /></svg>
                        : null}
                    {item.type === "recentSearch" ?
                         <svg width="16" height="16" viewBox="0 0 16 16">
                        <defs>
                          <clipPath id="clip-Recent_Search">
                            <rect width="16" height="16" />
                          </clipPath>
                        </defs>
                        <g id="Recent_Search" data-name="Recent Search" clip-path="url(#clip-Recent_Search)">
                          <g id="clock-outline">
                            <g id="clock-outline-2" data-name="clock-outline" transform="translate(0 0)">
                              <g id="clock">
                                <path
                                  id="Path_5"
                                  data-name="Path 5"
                                  d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm0,14.4A6.4,6.4,0,1,1,16.4,10,6.4,6.4,0,0,1,10,16.4Z"
                                  transform="translate(-2 -2)"
                                  fill="#aaaaac"
                                />
                                <path
                                  id="Path_6"
                                  data-name="Path 6"
                                  d="M15.9,10.919H12.96V7.98a.98.98,0,0,0-1.96,0V11.9a.98.98,0,0,0,.98.98H15.9a.98.98,0,0,0,0-1.96Z"
                                  transform="translate(-4.494 -3.385)"
                                  fill="#aaaaac"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                        : null}
                    {item.type === "autoSuggestion" ?
                        <svg className="su__mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /><path d="M0 0h24v24H0z" fill="none" /></svg>
                        : null}
                </div>
            </Fragment>
        );
    } catch (e) {
        console.log('Error in AutoCompleteResultIcon component', e);
        return (<div></div>);
    }
};

export default React.memo(AutoCompleteResultIcon);