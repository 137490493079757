import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import rootEpic from './epic';
import adEpic from './advertisementEpic';
import recommendationsEpic from "./recomendations";
import autocomplete from "./autoCompleteEpic";
import pageRatingEpic from "./pageRatingEpic";
import facetPreferenceEpic from "./facetPreferenceEpic";
import rootReducer from './ducks';
import suGPTEpic from './suGPTEpic';

const epicMiddleware = createEpicMiddleware();

export default () => {

  const store = createStore(
    rootReducer,
    // eslint-disable-next-line no-underscore-dangle
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(epicMiddleware)
  );

  epicMiddleware.run(rootEpic);
  epicMiddleware.run(adEpic);
  epicMiddleware.run(recommendationsEpic);
  epicMiddleware.run(autocomplete)
  epicMiddleware.run(pageRatingEpic)
  epicMiddleware.run(facetPreferenceEpic);
  epicMiddleware.run(suGPTEpic);
  return store;
};
