import React from 'react';
import Icons from '../../../assets/svg-icon/svg';
import IconColors from '../../../IconColors';
const AutoCompleteSearchIcon = (props) => {
  try {
    let { redirection } = props;
    return (
      <button
        className="su__btn su__search_btn su__animate-zoom su__flex-vcenter su__position-absolute su__zindex su__bg-transparent su__rtlleft"
        onClick={(e) => {
          console.log(e);
          redirection();
        }}
      >
        <svg width="25" height="25" viewBox="0 0 12.35 12.35">
          <defs>
            <clipPath id="clip-search">
              <rect width="12.35" height="12.35"></rect>
            </clipPath>
          </defs>
          <g id="search" clip-path="url(#clip-search)">
            <path
              id="Icon_ionic-ios-search"
              data-name="Icon ionic-ios-search"
              d="M16.7,15.947l-3.433-3.466a4.893,4.893,0,1,0-.743.752l3.411,3.443a.528.528,0,0,0,.746.019A.532.532,0,0,0,16.7,15.947ZM9.422,13.276a3.864,3.864,0,1,1,2.733-1.132A3.84,3.84,0,0,1,9.422,13.276Z"
              transform="translate(-4.5 -4.493)"
            ></path>
          </g>
        </svg>
      </button>
    );
  } catch (e) {
    console.log('Error in Search Icon component', e);
    return <div></div>;
  }
};

export default React.memo(AutoCompleteSearchIcon);
