// import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/timer';
import variables from './variables';
import { suGPTSearch } from './ducks';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { sugptUrlReq } from './searchClientTypes';

const suGPTEpic = (action$) =>
  action$.pipe(
    ofType(suGPTSearch.start().type),
    mergeMap(async () => {
      try {
        let paramsUrlReq = await sugptUrlReq(variables.searchClientType, variables);
        const res = await fetch(paramsUrlReq.url, paramsUrlReq.req);
        if (res.ok) {
          return suGPTSearch.success(res); // Dispatch a success action with the data
        } else {
          throw new Error('Network response was not ok'); // Handle other non-success status codes as an error
        }
      } catch (err) {
        console.error(err);
        return suGPTSearch.success(`ERROR_GENERATING_${new Date()}`); // Let component handle the errors (to be show inside GPT section)
      }
    })
  );

export default suGPTEpic;
